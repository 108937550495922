import { useState } from 'react';
import { RecordCount } from 'components/RecordCount';
import { Title } from 'components/Title';

export default function HomePage() {
	
	const [pageReady, setPageReady] = useState(true);
	return (
		<main id="HomePage" className="main-page">
<section className="page-section mb-3" >
    <div className="container-fluid">
        <div className="grid ">
            <div className="col comp-grid" >
                <Title title="SATU DATA PHL WILAYAH BPHL XVI MANOKWARI"   titleClass="font-bold text-center text-primary" subTitleClass="text-500"       />
            </div>
        </div>
    </div>
</section>
<section className="page-section mb-3" >
    <div className="container-fluid">
        <div className="grid justify-content-center">
            <div className="col-12 comp-grid" >
                <div className="card  s">
                    <img src="images/buat_depan.jpeg"   style={{maxWidth:'100%', width:'40%', height:'40%'}} />
                </div>
            </div>
        </div>
    </div>
</section>
<section className="page-section mb-3" >
    <div className="container-fluid">
        <div className="grid ">
            <div className="col-3 comp-grid" >
                <RecordCount title="PRODUKSI KAYU BULAT" description="Total Pkb" icon="pi pi-th-large" apiPath="components_data/getcount_produksikayubulat" link={`//pkb/list`}     />
            </div>
            <div className="col-3 comp-grid" >
                <RecordCount title="PRODUKSI KAYU OLAHAN" description="Total Pko" icon="pi pi-th-large" apiPath="components_data/getcount_produksikayuolahan" link={`//pko/list`}     />
            </div>
            <div className="col-3 comp-grid" >
                <RecordCount title="HASIL HUTAN BUKAN KAYU" description="Total Hhbk" icon="pi pi-th-large" apiPath="components_data/getcount_hasilhutanbukankayu" link={`//hhbk/list`}     />
            </div>
            <div className="col-3 comp-grid" >
                <RecordCount title="PEREDARAN HASIL HUTAN" description="Total Phh" icon="pi pi-th-large" apiPath="components_data/getcount_peredaranhasilhutan" link={`//phh/list`}     />
            </div>
            <div className="col-3 comp-grid" >
                <RecordCount title="PENDAPATAN NEGARA BUKAN PAJAK" description="Total Pnbp" icon="pi pi-th-large" apiPath="components_data/getcount_pendapatannegarabukanpajak" link={`//pnbp/list`}     />
            </div>
            <div className="col-12 comp-grid" >
            </div>
        </div>
    </div>
</section>
		</main>
	);
}
