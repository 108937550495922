import { Routes, Route } from 'react-router-dom';

import IndexLayout from 'layouts/IndexLayout';
import MainLayout from 'layouts/MainLayout';
import Page from 'components/Page';
import HomePage from './pages/home/HomePage';
import IndexPages from './pages/index';
import ErrorPages from './pages/errors';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'assets/styles/layout.scss';
const App = () => {
	return (
		<Routes>
			<Route element={<MainLayout />}>
				<Route path="/" element={<HomePage />} />
				<Route path="/home" element={<HomePage />} />
				

				{/* hhbk pages routes */}
				<Route path="/hhbk" element={<Page path="hhbk/List"/>} />
				<Route path="/hhbk/:fieldName/:fieldValue" element={<Page path="hhbk/List"/>} />
				<Route path="/hhbk/index/:fieldName/:fieldValue" element={<Page path="hhbk/List"/>} />
				<Route path="/hhbk/view/:pageid" element={<Page path="hhbk/View"/>} />
				<Route path="/hhbk/add" element={<Page path="hhbk/Add"/>} />
				<Route path="/hhbk/edit/:pageid" element={<Page path="hhbk/Edit"/>} />

				{/* jml_pkb pages routes */}
				<Route path="/jml_pkb" element={<Page path="jml_pkb/List"/>} />
				<Route path="/jml_pkb/:fieldName/:fieldValue" element={<Page path="jml_pkb/List"/>} />
				<Route path="/jml_pkb/index/:fieldName/:fieldValue" element={<Page path="jml_pkb/List"/>} />

				{/* kph pages routes */}
				<Route path="/kph" element={<Page path="kph/List"/>} />
				<Route path="/kph/:fieldName/:fieldValue" element={<Page path="kph/List"/>} />
				<Route path="/kph/index/:fieldName/:fieldValue" element={<Page path="kph/List"/>} />
				<Route path="/kph/view/:pageid" element={<Page path="kph/View"/>} />
				<Route path="/kph/add" element={<Page path="kph/Add"/>} />
				<Route path="/kph/edit/:pageid" element={<Page path="kph/Edit"/>} />

				{/* list_kph pages routes */}
				<Route path="/list_kph" element={<Page path="list_kph/List"/>} />
				<Route path="/list_kph/:fieldName/:fieldValue" element={<Page path="list_kph/List"/>} />
				<Route path="/list_kph/index/:fieldName/:fieldValue" element={<Page path="list_kph/List"/>} />
				<Route path="/list_kph/view/:pageid" element={<Page path="list_kph/View"/>} />
				<Route path="/list_kph/add" element={<Page path="list_kph/Add"/>} />
				<Route path="/list_kph/edit/:pageid" element={<Page path="list_kph/Edit"/>} />

				{/* list_pbphh pages routes */}
				<Route path="/list_pbphh" element={<Page path="list_pbphh/List"/>} />
				<Route path="/list_pbphh/:fieldName/:fieldValue" element={<Page path="list_pbphh/List"/>} />
				<Route path="/list_pbphh/index/:fieldName/:fieldValue" element={<Page path="list_pbphh/List"/>} />
				<Route path="/list_pbphh/view/:pageid" element={<Page path="list_pbphh/View"/>} />
				<Route path="/list_pbphh/add" element={<Page path="list_pbphh/Add"/>} />
				<Route path="/list_pbphh/edit/:pageid" element={<Page path="list_pbphh/Edit"/>} />

				{/* pbph pages routes */}
				<Route path="/pbph" element={<Page path="pbph/List"/>} />
				<Route path="/pbph/:fieldName/:fieldValue" element={<Page path="pbph/List"/>} />
				<Route path="/pbph/index/:fieldName/:fieldValue" element={<Page path="pbph/List"/>} />
				<Route path="/pbph/view/:pageid" element={<Page path="pbph/View"/>} />
				<Route path="/pbph/add" element={<Page path="pbph/Add"/>} />
				<Route path="/pbph/edit/:pageid" element={<Page path="pbph/Edit"/>} />

				{/* pengguna pages routes */}
				<Route path="/pengguna" element={<Page path="pengguna/List"/>} />
				<Route path="/pengguna/:fieldName/:fieldValue" element={<Page path="pengguna/List"/>} />
				<Route path="/pengguna/index/:fieldName/:fieldValue" element={<Page path="pengguna/List"/>} />
				<Route path="/pengguna/view/:pageid" element={<Page path="pengguna/View"/>} />
				<Route path="/pengguna/add" element={<Page path="pengguna/Add"/>} />
				<Route path="/pengguna/edit/:pageid" element={<Page path="pengguna/Edit"/>} />

				{/* phh pages routes */}
				<Route path="/phh" element={<Page path="phh/List"/>} />
				<Route path="/phh/:fieldName/:fieldValue" element={<Page path="phh/List"/>} />
				<Route path="/phh/index/:fieldName/:fieldValue" element={<Page path="phh/List"/>} />
				<Route path="/phh/view/:pageid" element={<Page path="phh/View"/>} />
				<Route path="/phh/add" element={<Page path="phh/Add"/>} />
				<Route path="/phh/edit/:pageid" element={<Page path="phh/Edit"/>} />

				{/* pkb pages routes */}
				<Route path="/pkb" element={<Page path="pkb/List"/>} />
				<Route path="/pkb/:fieldName/:fieldValue" element={<Page path="pkb/List"/>} />
				<Route path="/pkb/index/:fieldName/:fieldValue" element={<Page path="pkb/List"/>} />
				<Route path="/pkb/view/:pageid" element={<Page path="pkb/View"/>} />
				<Route path="/pkb/add" element={<Page path="pkb/Add"/>} />
				<Route path="/pkb/edit/:pageid" element={<Page path="pkb/Edit"/>} />

				{/* pko pages routes */}
				<Route path="/pko" element={<Page path="pko/List"/>} />
				<Route path="/pko/:fieldName/:fieldValue" element={<Page path="pko/List"/>} />
				<Route path="/pko/index/:fieldName/:fieldValue" element={<Page path="pko/List"/>} />
				<Route path="/pko/view/:pageid" element={<Page path="pko/View"/>} />
				<Route path="/pko/add" element={<Page path="pko/Add"/>} />
				<Route path="/pko/edit/:pageid" element={<Page path="pko/Edit"/>} />

				{/* pnbp pages routes */}
				<Route path="/pnbp" element={<Page path="pnbp/List"/>} />
				<Route path="/pnbp/:fieldName/:fieldValue" element={<Page path="pnbp/List"/>} />
				<Route path="/pnbp/index/:fieldName/:fieldValue" element={<Page path="pnbp/List"/>} />
				<Route path="/pnbp/view/:pageid" element={<Page path="pnbp/View"/>} />
				<Route path="/pnbp/add" element={<Page path="pnbp/Add"/>} />
				<Route path="/pnbp/edit/:pageid" element={<Page path="pnbp/Edit"/>} />

				{/* profil pages routes */}
				<Route path="/profil" element={<Page path="profil/List"/>} />
				<Route path="/profil/:fieldName/:fieldValue" element={<Page path="profil/List"/>} />
				<Route path="/profil/index/:fieldName/:fieldValue" element={<Page path="profil/List"/>} />
				<Route path="/profil/view/:pageid" element={<Page path="profil/View"/>} />
				<Route path="/profil/add" element={<Page path="profil/Add"/>} />
				<Route path="/profil/edit/:pageid" element={<Page path="profil/Edit"/>} />
			</Route>
			<Route exact element={<IndexLayout />}>
				<Route path="/*" element={<IndexPages />} />
				<Route path="/error/*" element={<ErrorPages />} />
			</Route>
		</Routes>
	);
}
export default App;
